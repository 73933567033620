
@media screen and (min-width: 1600px) {
.container {
    max-width: 1544px;
    width: 1544px;
    }
}

a {
    color: #ffffff;
}

/**********************************************************************/
/* select styles */

.select select {
    background-color: #343c3d;
    color: white;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 15px;    
    border-color: #8C9B9D;
}

.select:not(.is-multiple):not(.is-loading)::after {
    border-color: #8c9b9d;
}

select select.is-hovered, .select select:hover {
    border-color: #ffffff;
}

.select select.is-active, .select select.is-focused, .select select:active, .select select:focus {
    border-color: #ffffff;    
}

.select select[disabled]:-moz-placeholder, .select select[disabled]::-moz-placeholder {
    color: rgba(255, 255, 255, .3);
}

.select select[disabled]::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .3);
}

.select select[disabled]:-ms-input-placeholder {
    color: rgba(255, 255, 255, .3);
}

.select select::-moz-placeholder {
    color: white;
}

.select select::-webkit-input-placeholder {
    color: white;
}

.switch[type=checkbox].is-outlined.switch-off-is-red+label::after, .switch[type=checkbox].is-outlined.switch-off-is-red+label:after {
    background-color: #e74c3c;
}

.switch[type=checkbox].is-outlined.switch-off-is-red+label::before, .switch[type=checkbox].is-outlined.switch-off-is-red+label:before {
    border-color: #e74c3c;
}

.switch[type=checkbox].is-outlined.switch-off-is-red:checked+label::after, .switch[type=checkbox].is-outlined.switch-off-is-red:checked+label:after {
    background-color: #00d1b2;
}

.switch[type=checkbox].is-outlined.switch-off-is-red:checked+label::before, .switch[type=checkbox].is-outlined.switch-off-is-red:checked+label:before {
    border-color: #00d1b2;
}

/**********************************************************************/
/* button styles */

.button {
    background-color: #343c3d;
    color: white;
    border-color: #8c9b9d;
}

.button:not(.is-multiple):not(.is-loading)::after {
    border-color: #8c9b9d;
}

button button.is-hovered, .button button:hover {
    border-color: #ffffff;
}

.button.is-focused, .button:focus {
    border-color: #ffffff;
    color: white;
}

.field.has-addons .control:first-child .button {
    border-top-left-radius:0.4rem;
    border-bottom-left-radius:0.4rem
}

.button:hover {
    border-color: white;    
}

/**********************************************************************/
/* input styles */

.input {
    background-color: #343c3d;
    color: white;
    border-color: #8c9b9d;
}

.input:hover {
    border-color: white;    
}

.input[disabled]:-moz-placeholder, .input[disabled]::-moz-placeholder {
    color: rgba(255, 255, 255, .3);
}

.input[disabled]::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .3);
}

.input[disabled]:-ms-input-placeholder {
    color: rgba(255, 255, 255, .3);
}

.field.has-addons .control:last-child .input {
    border-top-left-radius:0.4rem;
    border-bottom-left-radius:0.4rem
}

.input:-moz-placeholder, .input::-moz-placeholder {
    color: white;
}

.input::-webkit-input-placeholder {
    color: white;
}

.input:-ms-input-placeholder {
    color: white;
}

/**********************************************************************/
/* textarea */

.textarea {
    background-color: #343c3d;
    color: white;
    border-color: #8c9b9d;
}

.textarea:-moz-placeholder, .textarea::-moz-placeholder {
    color: white;
}

.textarea::-webkit-input-placeholder {
    color: white;
}

.textarea:-ms-input-placeholder {
    color: white;
}

.textarea[disabled]:-moz-placeholder, .textarea[disabled]::-moz-placeholder {
    color: rgba(255, 255, 255, .3);
}

.textarea[disabled]::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .3);
}

.textarea[disabled]:-ms-input-placeholder {
    color: rgba(255, 255, 255, .3);
}


/**********************************************************************/
/* label styles */

.label { 
    font-weight: 400;
    color: white;
}

/**********************************************************************/
/* radio styles */

.radio {
    padding-left:0.5rem;
    padding-right:0.5rem;
}

/*********************************************************************/
/* Bulma various styles */

.tile {
    margin: 0.1rem;
    border-radius: 14px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.section {
    padding: 1.5rem 1.5rem;
}

.subtitle {
    color: #ffffff;
}

.pagination-link, .pagination-next, .pagination-previous {
    border-color: #5e6d6f;
    color: #ffffff;
    min-width: 2.25em;
}

.pagination-link:hover, .pagination-next:hover, .pagination-previous:hover {
    border-color: #ffffff;
    color: #ffffff;
}


/***********************************************************************/
/* Common meal styles */

.meal-chef-box {
    border: 1px solid black;   
    border-radius: 8px;
    border: 1px solid black;
    overflow: hidden;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.meal-chef-row {
    padding: 6px;
}

.meal-chef-ok {
    color: #1abc9c80 !important;    
}

.meal-chefs-ok {
    background-color: /*rgba(0, 180, 0, 0.3);*/ #1abc9c50;
    cursor:pointer;
}

.meal-chefs-missingsome {
    background-color: rgba(180, 120, 0, 0.3);
}

.meal-reservation-group {
    border-radius: 8px;
    border: 1px solid black;
    overflow: hidden;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem ;
    padding-left: 0 !important;
    padding-right: 0 !important;    
}

.meal-reservation-row {    
    padding: 6px;
}

.meal-reservation-row-even {
    background: black;        
}

.meal-reservation-row-odd {
    background: #343c3d;
}

.guest-btn-has-guests {
    color: #00d1b2;
    border-color: #00d1b2;
}

.guest-btn-has-no-guests {    
    border-color: #4c5759;
}

.takeaway-btn-is-active {
    color: #00d1b2 !important;    
    border-color: #00d1b2 !important;
}

.takeaway-btn-is-not-active {
    border-color: #8c9b9d;
}

.mobile-hidden {
    display: none;
}

#shoppingInfoModal div section div.columns {
    margin-bottom:0;margin-top:0;
}

#shoppingInfoModal div section div.columns div.column{
    padding-top:0;padding-right:0;
}

.is-paddingless-top-bottom {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
}
  
@media screen and (max-width:768px) {  
    .is-hidden-mobile-bottommarginfix {
      margin-bottom: 0rem !important;
    }
}

@media screen and (min-width:769px) {
    .is-visible-mobile-only {
        display: none !important;
    }
}

@media screen and (max-width:769px) {
    .is-visible-mobile-only {        
    }
}

fieldset {
    border: none;
}

.button.is-static {
    background-color: #343c3d;
    border-color: rgb(140, 155, 157);;
}

.button[disabled], .input[disabled] {
    cursor: auto;
    background-color: #545c5d;
    border-color: #8c9b9d;
    color: white;
    -webkit-text-fill-color: white;
    opacity: 1;
}

@media screen and (min-width: 769px), print {
    .is-extra-wide .field-label {
      flex-grow: 2; /* overwrites previous value of 1 */
    }
  }


/* flatpickr overrides */
.flatpickr-calendar {
    background-color: #282f2f !important;
}

span.flatpickr-weekday {
    font-size: 15px !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year
{
    font-size: 15px !important;
}

.flatpickr-weekdays {
    background-color: rgb(63, 68, 88) !important;    
}

.flatpickr-day.selected, .flatpickr-day.startRange, 
.flatpickr-day.endRange, .flatpickr-day.selected.inRange, 
.flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, 
.flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, 
.flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, 
.flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, 
.flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, 
.flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, 
.flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
    background-color : rgb(46, 204, 113) !important;
    border-color : rgb(46, 204, 113) !important;
}